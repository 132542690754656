import { Box, HStack, TableContainer, Text } from '@chakra-ui/react'

import {
  CitizenNotificationMethod,
  GetLetterDto,
} from '~shared/dtos/letters.dto'
import { pluraliseIfNeededForCount } from '~utils/stringUtils'

import { IssuedBatchDetailsKeywordsTable } from '../tableComponents/IssuedBatchDetailsKeywordsTable'
import { IssuedBatchDetailsMainTable } from '../tableComponents/IssuedBatchDetailsMainTable'

export interface IssuedBatchViewLetterTableProps {
  letters: GetLetterDto[]
  notificationMethod?: CitizenNotificationMethod
  isAuthEnabled: boolean
}

export const IssuedBatchViewLetterTable = ({
  letters,
  notificationMethod,
  isAuthEnabled,
}: IssuedBatchViewLetterTableProps): JSX.Element => {
  const rowHeight = notificationMethod ? '85px' : '60px'
  const count = letters.length
  const headers = Object.keys(
    JSON.parse(letters[0]?.fieldValues ?? '{}') as {
      [key: string]: string
    },
  )

  return (
    <Box width="100%" borderRadius={'10px'}>
      <Text textStyle={'caption-1'} float={'right'} hidden>
        Showing {count} of {count} {pluraliseIfNeededForCount(count, 'letter')}
      </Text>
      <TableContainer
        w="100%"
        display="flex"
        mt={'10px'}
        borderRadius={'4px'}
        border={'1px solid'}
        borderColor={'grey.100'}
        borderBottom={'none'}
      >
        <HStack spacing="0px" flex="1">
          <IssuedBatchDetailsMainTable
            letters={letters}
            rowHeight={rowHeight}
            notificationMethod={notificationMethod}
            isAuthEnabled={isAuthEnabled}
          />
          <IssuedBatchDetailsKeywordsTable
            headers={headers}
            letters={letters}
            rowHeight={rowHeight}
          />
        </HStack>
      </TableContainer>
    </Box>
  )
}
