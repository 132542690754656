import {
  Box,
  Button,
  Hide,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Td,
  Text,
  Tr,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { Badge, Pagination } from '@opengovsg/design-system-react'
import { useMemo, useState } from 'react'
import { BiDotsVerticalRounded } from 'react-icons/bi'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { routes } from '~constants/routes'
import { useAdminAuth } from '~features/auth/context/AdminProtectedContext'
import { ManageCollaboratorAccessModal } from '~features/template-library/components/modals/ManageCollaboratorAccessModal'
import { TemplatePreviewThumbnail } from '~features/template-library/components/thumbnail/TemplatePreviewThumbnail'
import { GrowthBookFeatures } from '~shared/types/feature-flag'
import { getLocaleTimeFromDate, parseDateV2WithDay } from '~utils/dateUtils'
import {
  capitalizeFirstLetter,
  citizenNotificationMethodToString,
  pluraliseIfNeededForCount,
} from '~utils/stringUtils'

import {
  useGetBatchByIdFetchQuery,
  useGetBatches,
  useGetBatchPdfById,
} from '../hooks/dashboard.hooks'
import { handleDownloadCsvReport } from './DownloadCsvReportButton'
import { HeaderCell } from './tableComponents/HeaderCell'

export const PAGE_SIZE = 10
const DEFAULT_PAGE_SEARCH_PARAM = '1'

export const IssuedBatchesTable = (): JSX.Element => {
  const navigate = useNavigate()
  const { adminUser } = useAdminAuth()

  const [searchParams, setSearchParams] = useSearchParams()

  const currentPage = useMemo(
    () =>
      parseInt(searchParams.get('currentPage') || DEFAULT_PAGE_SEARCH_PARAM) -
      1,
    [searchParams],
  )

  const { batches, count } = useGetBatches(PAGE_SIZE, currentPage * PAGE_SIZE)
  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [shareAccessTemplateId, setShareAccessTemplateId] = useState<number>(-1)
  const [shareAccessTemplateName, setShareAccessTemplateName] =
    useState<string>()

  const handleShareAccess = (id: number, name: string) => {
    setShareAccessTemplateId(id)
    setShareAccessTemplateName(name)
    onOpen()
  }

  const { getBatchPdfUrl } = useGetBatchPdfById()
  const { getBatchById } = useGetBatchByIdFetchQuery()

  return (
    <VStack w="100%" pt={4} pb={14}>
      <Box w={'100%'}>
        <Text textStyle={'caption-1'} pl={2} float={'right'}>
          Showing {PAGE_SIZE * currentPage + 1}-
          {Math.min(
            PAGE_SIZE * (currentPage + 1),
            count ?? PAGE_SIZE * (currentPage + 1),
          )}{' '}
          of {count} {pluraliseIfNeededForCount(count ?? 0, 'batch', 'es')}
        </Text>
      </Box>
      <TableContainer
        w="100%"
        overflowX="auto"
        borderRadius={'4px'}
        border={'1px solid'}
        borderColor={'grey.100'}
        borderBottom={'none'}
      >
        <Table size="sm">
          <colgroup>
            <col width={'35%'} />
            <col width={'14%'} />
            <col width={'17%'} />
            <col width={'14%'} />
            <col width={'10%'} />
            <col width={'10%'} />
          </colgroup>
          <Tr backgroundColor={'grey.50'}>
            <HeaderCell>Letters issued</HeaderCell>
            <HeaderCell>Sent on</HeaderCell>
            <HeaderCell>Issued by</HeaderCell>
            <HeaderCell>Delivered by</HeaderCell>
            <HeaderCell>Letters read</HeaderCell>
            <HeaderCell>
              <Text textAlign="center" w={'100%'}>
                Actions
              </Text>
            </HeaderCell>
          </Tr>
          {batches &&
            batches.map((batch) => {
              return (
                <Box
                  key={batch.batchId}
                  height="75px"
                  display="table-row"
                  backgroundColor={'standard.white'}
                  cursor="pointer"
                  _hover={{
                    backgroundColor: 'brand.primary.100',
                  }}
                  onClick={() => {
                    navigate(
                      `/admin/${routes.admin.batches}/${batch.batchId}`,
                      {
                        state: 'isFromDashboard',
                      },
                    )
                  }}
                >
                  <Td textStyle={'subhead-2'} verticalAlign={'middle'}>
                    <HStack spacing={3}>
                      <Hide below="md">
                        <Box w={'60px'}>
                          <TemplatePreviewThumbnail
                            html={batch.templateHtml}
                            aspectRatio={'1'}
                            border={'1px solid'}
                            borderColor={'grey.100'}
                            padding={'1px'}
                          />
                        </Box>
                      </Hide>
                      <Box
                        textOverflow={'ellipsis'}
                        whiteSpace={'break-spaces'}
                        noOfLines={2}
                        maxW={'410px'}
                      >
                        <Text textStyle={'subhead-2'}>
                          {batch.templateName}
                        </Text>
                      </Box>
                    </HStack>
                  </Td>
                  <Td textStyle={'subhead-2'} verticalAlign={'middle'}>
                    <Text>{parseDateV2WithDay(batch.createdAt)}</Text>
                    <Text textStyle={'caption-2'} pt={1} color={'grey.400'}>
                      {getLocaleTimeFromDate(batch.createdAt)}
                    </Text>
                  </Td>
                  <Td textStyle={'subhead-2'} verticalAlign={'middle'}>
                    <Box
                      textOverflow={'ellipsis'}
                      whiteSpace={'break-spaces'}
                      noOfLines={1}
                      maxW={'200px'}
                    >
                      <Text>
                        {batch.senderEmail === adminUser?.email
                          ? 'me'
                          : batch.senderEmail}
                      </Text>
                    </Box>
                  </Td>
                  <Td verticalAlign={'middle'}>
                    <Text textStyle={'subhead-2'}>
                      {batch.notificationMethod
                        ? capitalizeFirstLetter(
                            citizenNotificationMethodToString(
                              batch.notificationMethod,
                            ) +
                              (batch.isAuthEnabled
                                ? ' (sgID login required)'
                                : ''),
                          )
                        : 'Other services'}
                    </Text>
                  </Td>
                  <Td
                    textStyle={'subhead-2'}
                    verticalAlign={'middle'}
                  >{`${batch.readCount}/${batch.totalCount}`}</Td>
                  <Td textAlign="center" verticalAlign={'middle'}>
                    <Menu placement="bottom-end">
                      <MenuButton
                        as={Button}
                        variant={'none'}
                        border={'none'}
                        padding={0}
                        color={'interaction.main.default'}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <Icon as={BiDotsVerticalRounded} boxSize={6} />
                      </MenuButton>
                      <MenuList mt={-3}>
                        <MenuItem
                          // eslint-disable-next-line @typescript-eslint/no-misused-promises
                          onClick={async (e) => {
                            e.stopPropagation()
                            await getBatchById({
                              batchId: batch.batchId,
                              errorMessage:
                                'Failed to generate report. Please try again later.',
                              onSuccess: (batchDataWithLetters) =>
                                handleDownloadCsvReport(batchDataWithLetters),
                            })
                          }}
                        >
                          Download CSV report
                        </MenuItem>
                        <MenuItem
                          onClick={(e) => {
                            e.stopPropagation()
                            getBatchPdfUrl(batch.batchId)
                          }}
                        >
                          <HStack>
                            <Text>Download PDFs</Text>
                            <Badge variant="subtle" borderRadius={'xl'}>
                              New
                            </Badge>
                          </HStack>
                        </MenuItem>
                        {sampleTemplateId !== batch.templateId && (
                          <MenuItem
                            onClick={(e) => {
                              e.stopPropagation()
                              handleShareAccess(
                                batch.templateId,
                                batch.templateName,
                              )
                            }}
                          >
                            Share access
                          </MenuItem>
                        )}
                      </MenuList>
                    </Menu>
                  </Td>
                </Box>
              )
            })}
        </Table>
      </TableContainer>
      <Box w={'100%'} justifyContent={'end'}>
        {count && (
          <Box float={'right'}>
            <Pagination
              totalCount={count}
              pageSize={PAGE_SIZE}
              onPageChange={(newPageNumber) =>
                setSearchParams((params) => {
                  if (newPageNumber == 1) return {}
                  params.set('currentPage', newPageNumber.toString())
                  return params
                })
              }
              currentPage={currentPage + 1}
            />
          </Box>
        )}
      </Box>
      {sampleTemplateId !== shareAccessTemplateId && (
        <ManageCollaboratorAccessModal
          isOpen={isOpen}
          onClose={onClose}
          templateId={shareAccessTemplateId}
          templateName={shareAccessTemplateName}
        />
      )}
    </VStack>
  )
}
