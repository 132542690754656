import { LETTERSG_LOGO, SUPPORT_EMAIL } from '../constants/letters'
import { replaceWithParams } from './letters'

export const getEmailTemplateBody = ({
  agencyName = 'the government',
  logo = LETTERSG_LOGO,
  letterPublicId,
  domainName,
  userEmail,
  isAuthEnabled,
}: {
  agencyName?: string
  logo?: string
  letterPublicId: string
  domainName: string
  userEmail: string
  isAuthEnabled: boolean
}): string => {
  // our email format is customised to be compliant with email sanitisation logic from Postman
  // refer to https://github.com/opengovsg/postmangovsg/blob/master/shared/src/templating/xss-options.ts to see list of compliant attributes
  return `<div class="email-content">
  <p style="display: none;">Dear Sir/Madam, You have been issued a letter by ${agencyName}. Click on the button below to access your letter. Alternatively, you can copy and paste this letter link: <a href="${domainName}/${letterPublicId}" target="_blank" rel="noopener">${domainName}/${letterPublicId}</a> into your browser's address bar.</p>
  <table class="email-table" style="max-width: 728px; min-width: 500px; margin: 0 auto; font-size: 14px; line-height: 1.5; font-family: Helvetica, Arial, sans-serif;">
  <tbody>
  <tr>
  <td style="width: 100%; text-align: left; height: 28px; background-color: #e8ebee; border-top-left-radius: 8px; border-top-right-radius: 8px; white-space: nowrap; padding: 0 24px;"><span style="vertical-align: middle; line-height: 16px; font-size: 11px;"><a href="${domainName}" target="_blank" rel="noopener"><img style="width: 12px; height: 12px; display: inline; margin-top: 5px; margin-right: 8px;" src="https://file.go.gov.sg/masthead-lion.png" alt="Masthead Lion Symbol" width="16" height="16"></a>Singapore Government emails are sent from .gov.sg addresses</span></td>
  </tr>
  <tr>
  <td style="width: 100%; padding: 0 64px; border-color: #E8EBEE; border-style: solid; border-width: 0 2px 2px 2px; border-bottom-left-radius: 8px; border-bottom-right-radius: 8px;">
  <div class="body-content">
  <div class="body-content">
  <p style="border-collapse: collapse; width: 100%; border-color: #E8EBEE; border-style: solid; border-width: 0 0 2px 0; text-align: center;">
    <img src="${logo}" alt="" height="58" style="height: 58px; width: auto; margin: auto; display: block; margin-top: 10px; margin-bottom: 10px;">
  </p>
  <p>Dear Sir/Madam,</p>
  <p>You have received a letter from ${agencyName}.${
    isAuthEnabled
      ? ' To view the letter, you will be prompted to log in with Singpass.'
      : ''
  }</p>
  <a href="${domainName}/${letterPublicId}" target="_blank" rel="noopener"><img src="https://file.go.gov.sg/sn1q27.png" alt="" height="45" style="height: 45px; width: auto; display: block;"></a>
  <br>
  <p>Alternatively, you can copy and paste this letter link: <a href="${domainName}/${letterPublicId}" target="_blank" rel="noopener">${domainName}/${letterPublicId}</a> into your browser's address bar.</p>
  <p>For queries regarding the letter contents, please email the letter issuer at <a href="mailto:${userEmail}" target="_blank" rel="noopener">${userEmail}</a>.</p>
  <br>
  <p style="background-color: #f8f9fd; padding: 1rem; font-size: 12px; color: #64707d;">This letter is issued via LetterSG, a platform for trusted e-letters from the Singapore Government and public sector agencies. Before you click any links, check that it contains .gov.sg to ensure it is trusted government communication. If you have issues viewing the letter on <a href="${domainName}" target="_blank" rel="noopener">${domainName}</a>, please email us at <a href="mailto:${SUPPORT_EMAIL}" target="_blank" rel="noopener">${SUPPORT_EMAIL}</a>.</p>
  </div>
  <table style="font-size: 11px; color: rgb(100, 112, 125); margin: auto; height: 28px; width: 26.3423%;" role="presentation">
  <tbody>
  <tr>
  <td style="width: 32.0755%; vertical-align: bottom; color: #bbbbbb;">Built by</td>
  <td style="width: 67.9245%;" align="center"><img style="height: 26px; width: 92px; vertical-align: middle;" src="https://file.go.gov.sg/y65kqh.png" alt="Open Government Products Logo" height="24"></td>
  </tr>
  </tbody>
  </table>
  <p>&nbsp;</p>
  </div>
  </td>
  </tr>
  </tbody>
  </table>
  </div>`
}

export const getTemplateAccessTemplateBody = (
  officerEmail: string,
  templateName: string,
): string => {
  return `<p> <i>This communication is issued via LetterSG, a platform for trusted e-letters from the Singapore Government and statutory boards. Before you click any links, check that it contains .gov.sg to ensure it is trusted government communication.</i></p>
  
  <br/>
        <p></p>
        <p>Dear Sir/Madam,</p>
        <p></p>
        <p></p>
        <p>You have been granted access to a LetterSG template: <i>"${templateName}"</i> by ${officerEmail}.</p> 
        <p>Log in to <a target="_blank" href="https://letters.gov.sg">LetterSG</a> to view the template, issue letters and track all issued letters from your dashboard.</p>
        
        <br/>
        <p></p>
        <p>If you have any concerns or need assistance, please email us at <a target="_blank" href="mailto:${SUPPORT_EMAIL}">${SUPPORT_EMAIL}</a>.</p>
        `
}

export const getCustomSmsMessageTemplate = ({
  customSmsMessageMapping,
  templateId,
  letterPublicId,
  domainName,
}: {
  customSmsMessageMapping: { [key: string]: string }
  templateId: number
  letterPublicId: string
  domainName: string
}): string => {
  for (const key in customSmsMessageMapping) {
    const ids = key
      .split(',')
      .map((id) => Number(id.trim()))
      .filter((id) => !isNaN(id))
    if (ids.includes(templateId)) {
      const customSmsMessageTemplate = customSmsMessageMapping[key]
      return replaceWithParams(customSmsMessageTemplate, {
        letter_link: `${domainName}/${letterPublicId}`,
      })
    }
  }
  return ''
}

export const getDefaultSmsMessageTemplate = ({
  agencyName = 'the government',
  letterPublicId,
  domainName,
}: {
  agencyName?: string
  letterPublicId: string
  domainName: string
}): string => {
  return `Dear Sir/Madam, you have received a letter from ${agencyName}:\n\n${domainName}/${letterPublicId}\n\nBefore you click any links, check that it contains .gov.sg to ensure it is trusted government communication.`
}

export const getDefaultGovSgSmsMessageTemplate = ({
  agencyName = 'the government',
  letterPublicId,
  domainName,
}: {
  agencyName?: string
  letterPublicId: string
  domainName: string
}): string => {
  return `Dear Sir/Madam, you have received a letter from ${agencyName}:\n\n${domainName}/${letterPublicId}`
}

export const getGovSgSmsWithCustomHeaderMessageTemplate = ({
  letterPublicId,
  domainName,
}: {
  agencyName?: string
  letterPublicId: string
  domainName: string
}): string => {
  return `Dear Sir/Madam, we have sent you a digital letter. Please view it here:\n\n${domainName}/${letterPublicId}`
}

export const getSmsMessageTemplate = ({
  customSmsMessageMapping,
  templateId,
  agencyName,
  letterPublicId,
  domainName,
  govSgFormatEnabled = false,
  govSgAgencyHasCustomHeader = false,
}: {
  customSmsMessageMapping?: { [key: string]: string }
  templateId?: number
  govSgFormatEnabled?: boolean
  govSgAgencyHasCustomHeader?: boolean
  agencyName?: string
  letterPublicId: string
  domainName: string
}): string => {
  if (
    customSmsMessageMapping &&
    templateId &&
    getCustomSmsMessageTemplate({
      customSmsMessageMapping,
      templateId,
      letterPublicId,
      domainName,
    })
  )
    return getCustomSmsMessageTemplate({
      customSmsMessageMapping,
      templateId,
      letterPublicId,
      domainName,
    })
  if (govSgFormatEnabled && govSgAgencyHasCustomHeader)
    return getGovSgSmsWithCustomHeaderMessageTemplate({
      letterPublicId,
      domainName,
    })
  if (govSgFormatEnabled)
    return getDefaultGovSgSmsMessageTemplate({
      agencyName,
      letterPublicId,
      domainName,
    })
  return getDefaultSmsMessageTemplate({
    agencyName,
    letterPublicId,
    domainName,
  })
}
