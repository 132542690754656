import {
  IsArray,
  IsDefined,
  IsNumber,
  IsOptional,
  IsString,
} from 'class-validator'

import {
  GrowthBookFeatures,
  GrowthBookFeaturesMap,
} from '../types/feature-flag'
import { NotificationStatus } from './notifications.dto'

export class CreateLetterDto {
  userId: number
  batchId: number
  templateId: number
  templateVersion: number
  issuedLetter: string
  fieldValues: string
  shortLink: string
  anchorText: string
}
export class UpdateLetterDto {}

export type LetterParamMap = { [key: string]: string }
export type LetterParamMaps = LetterParamMap[]

export enum LetterSource {
  API = 'API',
  SAMPLE = 'SAMPLE',
  CONSOLE = 'CONSOLE',
}

export enum CitizenNotificationMethod {
  EMAIL = 'EMAIL',
  SMS = 'SMS',
  NONE = 'NONE',
  PHYSICAL = 'PHYSICAL',
}

export class CreateNotificationDto {
  @IsDefined()
  @IsArray()
  recipients: string[] | { [key: string]: string }[]
  @IsDefined()
  notificationMethod: CitizenNotificationMethod
}

export class CreateSingleLetterDto {
  @IsDefined()
  @IsNumber()
  templateId: number
  @IsDefined()
  letterParams: LetterParamMap
  @IsOptional()
  notificationParams?: CreateNotificationDto
}

export class CreateBulkLetterDto {
  @IsDefined()
  @IsNumber()
  templateId: number
  @IsDefined()
  @IsNumber()
  templateVersion: number
  @IsDefined()
  letterParamMaps: LetterParamMaps
  @IsOptional()
  createNotificationDto?: CreateNotificationDto
  @IsDefined()
  isAuthEnabled: boolean
  @IsOptional()
  nricFins?: string[]
}

export enum BulkLetterValidationResultErrorMessage {
  INVALID_ATTRIBUTE = 'Invalid attribute in param',
  MISSING_PARAM = 'Missing param',
  INVALID_PHONE_NUMBER = 'Invalid phone number',
  EXTRA_FIELD_IN_ROW = 'Extra field in row',
  INVALID_EMAIL = 'Invalid email',
  INVALID_ADDRESS = 'Invalid address',
  INVALID_NRIC = 'Invalid NRIC/FIN',
}

export class BulkLetterValidationResultError {
  id: number
  param: string
  message: BulkLetterValidationResultErrorMessage
  displayedErrorMessage: string
}

export class BulkLetterValidationResultDto {
  success: boolean
  message: string
  errors?: BulkLetterValidationResultError[]
}

export class GetLetterMetadataDto {
  agencyMapping?: GrowthBookFeaturesMap[GrowthBookFeatures.agencyMapping]
}

export class GetLetterPublicDto {
  publicId: string
  issuedLetter: string
  createdAt: Date
  firstReadAt: Date
  displayResponsive: boolean
  isAuthEnabled: boolean
  issuingAgency?: GrowthBookFeaturesMap[GrowthBookFeatures.agencyMapping]
}

export class GetLetterRecipientWithNotificationStatusDto {
  recipient: string
  notificationStatus: NotificationStatus
}

export class GetLetterDto {
  publicId: string
  createdAt: string
  firstReadAt: string
  issuedLetter: string
  fieldValues: string
  notifications?: GetLetterRecipientWithNotificationStatusDto[]
  isAuthEnabled: boolean
  nricFin?: string
}

export class GetLettersDto {
  letters: GetLetterDto[]
  count: number
}

export class GetBulkLetterDto {
  publicId: string
  createdAt: string;
  [key: string]: string
}

export class IssueSampleLetterDto {
  @IsDefined()
  @IsNumber()
  templateId: number
  @IsDefined()
  createNotificationDto: CreateNotificationDto
}
