import { RouteObject } from 'react-router-dom'

import { PublicLayout } from '~/layouts/PublicLayout'
import { LandingPage } from '~features/landing/LandingPage'
import { AuthEnabledLetterCallbackPage } from '~features/public/AuthEnabledLetterCallbackPage'
import { AuthEnabledLetterLoginPage } from '~features/public/AuthEnabledLetterLoginPage'
import { LetterPublicPage } from '~features/public/LetterPublicPage'

import { Redirect } from '../Redirect'

export const publicRoutes: RouteObject[] = [
  {
    index: true,
    element: <LandingPage />,
  },
  {
    path: `letters/:letterPublicId`,
    element: <Redirect to="/:letterPublicId" />,
  },
  {
    path: `:letterPublicId`,
    element: <PublicLayout />,
    children: [
      {
        index: true,
        element: <LetterPublicPage />,
      },
    ],
  },
  {
    path: `sgid/callback`, // To ask ziwei to change the sgid login requestor name from volunteersg to lettersg when we are ready
    element: <AuthEnabledLetterCallbackPage />,
  },
]
