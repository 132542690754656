import { useState } from 'react'

import {
  ADDRESS_KEYS,
  BULK_MAX_ROW_LENGTH,
  EMAIL_KEY,
  NRICFIN_KEY,
  PHONENUMBER_KEY,
} from '~shared/constants/letters'
import { extractAddressFields, extractFields } from '~shared/util/csvUtils'
import { csvToJsonArr } from '~utils/csvUtils'

const useParseCsv = (templateFields: string[]) => {
  const [parsedArr, setParsedArr] = useState<Array<{ [key: string]: string }>>(
    [],
  )
  const [error, setError] = useState<string[]>([])
  const [phoneNumbers, setPhoneNumbers] = useState<string[]>([])
  const [emails, setEmails] = useState<string[]>([])
  const [nricFins, setNricFins] = useState<string[]>([])
  const [parsedFields, setParsedFields] = useState<unknown[]>([])
  const [addresses, setAddresses] = useState<{ [key: string]: string }[]>([])
  const validateFields = (csvFields: string[]) => {
    error.length = 0
    const missingFields: string[] = []
    const extraFields: string[] = []

    templateFields
      .filter((field) => !csvFields.includes(field))
      .forEach((missingField) => missingFields.push(missingField))

    csvFields
      .filter(
        (key) => key !== '__parsed_extra' && !templateFields.includes(key),
      )
      .forEach((extraField) => extraFields.push(extraField))

    const errorArr = `${
      missingFields.length
        ? `Add missing column headers: ${missingFields.join(', ')}. `
        : ''
    }${
      extraFields.length
        ? `Remove extra column headers: ${extraFields.join(', ')}.`
        : ''
    }`

    if (errorArr) {
      setError([errorArr])
      return
    }
  }

  const uploadCsv = async (file?: File): Promise<void> => {
    setError([])
    setPhoneNumbers([])
    setParsedArr([])
    setParsedFields([])
    setAddresses([])
    setNricFins([])

    if (!file) return

    let resolvedData: unknown[] = []
    let resolvedFields: unknown[]
    try {
      const result = (await csvToJsonArr(file)) as {
        data: unknown[]
        fields: unknown[]
      }
      resolvedData = result.data
      resolvedFields = result.fields
      setParsedFields(resolvedFields)
    } catch (error) {
      return setError(['Error parsing CSV file'])
    }

    if (resolvedFields.length === 0 || resolvedData.length === 0) {
      return setError(['Your CSV file is empty'])
    }

    if (resolvedData.length > BULK_MAX_ROW_LENGTH) {
      return setError([
        `Maximum of ${BULK_MAX_ROW_LENGTH} rows has been exceeded`,
      ])
    }

    const csvFields = resolvedFields as Array<string>
    validateFields(csvFields)

    if (error.length > 0) {
      return setError(error)
    }

    if (csvFields.includes(PHONENUMBER_KEY)) {
      setPhoneNumbers(extractFields(resolvedData, PHONENUMBER_KEY))
    }

    if (csvFields.includes(EMAIL_KEY)) {
      setEmails(extractFields(resolvedData, EMAIL_KEY))
    }
    if (csvFields.includes(NRICFIN_KEY)) {
      setNricFins(extractFields(resolvedData, NRICFIN_KEY))
    }

    if (ADDRESS_KEYS.every((key) => csvFields.includes(key))) {
      setAddresses(
        extractAddressFields(resolvedData as { [key: string]: string }[]),
      )
    }

    setParsedArr(resolvedData as Array<{ [key: string]: string }>)
  }

  const revalidateCsv = () => {
    if (parsedFields.length == 0) return

    const csvFields = parsedFields as Array<string>
    validateFields(csvFields)
  }

  const reset = () => {
    setError([])
    setPhoneNumbers([])
    setParsedArr([])
    setParsedFields([])
    setAddresses([])
    setNricFins([])
  }

  return {
    parsedArr,
    error,
    phoneNumbers,
    uploadCsv,
    revalidateCsv,
    emails,
    addresses,
    nricFins,
    reset,
  }
}

export default useParseCsv
