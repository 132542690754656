import {
  Alert,
  AlertIcon,
  Box,
  HStack,
  Link,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { Helmet } from 'react-helmet'

import { CITIZEN_LETTER_FEEDBACK_SHOW_STATUS } from '~constants/localStorage'
import { useAdminAuth } from '~features/auth/context/AdminProtectedContext'
import { LetterViewer } from '~features/editor/components/LetterViewer'
import { onHtmlImagesLoaded, WIDTH_A4 } from '~utils/htmlUtils'

import { AuthEnabledLetterLoginPage } from './AuthEnabledLetterLoginPage'
import { CitizenMenu } from './components/CitizenMenu'
import { LocalStorageCitizenFeedbackData } from './components/feedback/constants/feedback'
import FeedbackForm from './components/feedback/FeedbackForm'
import { LetterFooterCard } from './components/LetterFooterCard'
import {
  useGetLetterByPublicId,
  useLetterPublicId,
} from './hooks/letters.hooks'
import { useTransformScale } from './hooks/useTransformScale'
import { PublicNotFoundComponent } from './PublicNotFoundComponent'

export const LetterPublicPage = (): JSX.Element => {
  const { letterPublicId } = useLetterPublicId()
  const { adminUser } = useAdminAuth()
  const transformScale = useTransformScale()
  const [letterHeight, setLetterHeight] = useState<number | undefined>(0)
  const letterViewerRef = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        // explicitly waits for images to load before setting letter height
        onHtmlImagesLoaded(node, () => setLetterHeight(node.offsetHeight))
      }
    },
    [setLetterHeight],
  )

  const {
    isOpen: isShowFeedbackOpen,
    onOpen: onShowFeedbackOpen,
    onClose: onShowFeedbackClose,
  } = useDisclosure()
  const observer = useRef<IntersectionObserver | null>(null)
  const isAlreadyShown = useRef<boolean>(false)

  const getStoredData = (): LocalStorageCitizenFeedbackData => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const storedData: LocalStorageCitizenFeedbackData | null = JSON.parse(
      localStorage.getItem(CITIZEN_LETTER_FEEDBACK_SHOW_STATUS) || '{}',
    )
    if (!storedData) {
      return {
        timesVisited: 0,
        isFormDone: false,
        lastShownTime: '',
      }
    }

    return {
      timesVisited: storedData.timesVisited || 0,
      isFormDone: storedData.isFormDone || false,
      lastShownTime: storedData.lastShownTime || '',
    }
  }

  // Create a ref for LetterFooterCard using useCallback
  const footerCardRef = useCallback((node: HTMLDivElement | null) => {
    if (observer.current) {
      observer.current.disconnect() // Disconnect any previous observer
    }

    if (node) {
      observer.current = new IntersectionObserver(
        ([entry]) => {
          // Directly access the first entry, only observing one element here
          if (entry.isIntersecting && !isAlreadyShown.current) {
            const { timesVisited, isFormDone, lastShownTime } = getStoredData()
            if (isFormDone) {
              return
            }
            const now = new Date()
            const lastShown = lastShownTime ? new Date(lastShownTime) : null
            // Calculate 90 days in milliseconds (90 days * 24 hours * 60 minutes * 60 seconds * 1000 ms)
            const ninetyDaysAgoDate = new Date(
              now.getTime() - 90 * 24 * 60 * 60 * 1000,
            )
            if (
              timesVisited === 3 &&
              lastShown &&
              lastShown > ninetyDaysAgoDate
            ) {
              return
            }

            // When LetterFooterCard is in view, show the form
            onShowFeedbackOpen()
            isAlreadyShown.current = true // Prevent showing the form multiple times
            localStorage.setItem(
              CITIZEN_LETTER_FEEDBACK_SHOW_STATUS,
              JSON.stringify({
                timesVisited: timesVisited < 3 ? timesVisited + 1 : 3,
                isFormDone,
                lastShownTime: now.toISOString(),
              }),
            )
          }
        },
        {
          threshold: 0.5, // Amount of visibility needed to trigger
        },
      )

      observer.current.observe(node) // Observe the newly rendered node
    }
  }, [])

  // Ensure the observer is cleaned up when the component unmounts
  useEffect(() => {
    return () => {
      if (observer.current) {
        observer.current.disconnect() // Cleanup the observer on unmount
      }
    }
  }, [])

  const { letter, isLetterLoading, error } = useGetLetterByPublicId({
    letterPublicId,
  })

  if (error) {
    const { message } = error as Error
    try {
      const parsedMessage = JSON.parse(message) as {
        statusCode: number
        message: string
      }
      if (
        parsedMessage.statusCode == 401 &&
        parsedMessage.message == 'You are not authorised to view this letter'
      ) {
        return <Text>{'You are not authorised to view this letter'}</Text>
      }
      if (parsedMessage.statusCode == 401) {
        return (
          <AuthEnabledLetterLoginPage
            authEnabledLetterPublicId={letterPublicId}
            message={parsedMessage.message}
          />
        )
      }
      return <PublicNotFoundComponent />
    } catch {
      return <PublicNotFoundComponent />
    }
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <div>
        <>
          <Box w="full" h="100%" bg="grey.100">
            <VStack spacing="0" pb={5}>
              {!!adminUser && (
                <Alert
                  status="info"
                  w={WIDTH_A4}
                  maxW={'90vw'}
                  my={3}
                  backgroundColor={'brand.primary.100'}
                  borderColor={'base.content.brand'}
                  borderWidth={'1px'}
                  borderRadius={'4px'}
                  mb="0"
                >
                  <HStack align={'top'} spacing="0">
                    <AlertIcon />
                    <Text textStyle={'body-2'} color={'brand.secondary.700'}>
                      You are currently logged in as an agency user. The read
                      status will not be updated. To test for a status update,
                      please open the letter in a separate device or log out of{' '}
                      <Link
                        href="https://letters.gov.sg"
                        color={'brand.secondary.700'}
                      >
                        letters.gov.sg
                      </Link>{' '}
                      beforehand.
                    </Text>
                  </HStack>
                </Alert>
              )}
              <CitizenMenu letter={letter} />
              <VStack
                align={'center'}
                height={
                  letter?.displayResponsive || !letterHeight
                    ? 'auto'
                    : letterHeight * transformScale
                }
                mt={'20px'}
                w={WIDTH_A4}
                maxW={'90vw'}
              >
                <LetterViewer
                  ref={letterViewerRef}
                  html={letter?.issuedLetter}
                  displayResponsive={letter?.displayResponsive}
                  isLoading={isLetterLoading}
                  transformScale={transformScale}
                />
              </VStack>
              {letterPublicId && letter && (
                <LetterFooterCard
                  ref={footerCardRef}
                  letterPublicId={letterPublicId}
                  displayResponsive={letter?.displayResponsive}
                  transformScale={transformScale}
                  createdAt={letter.createdAt}
                />
              )}
            </VStack>
            {!adminUser && letterPublicId && letter && isShowFeedbackOpen && (
              <FeedbackForm
                letterId={letterPublicId}
                onClose={onShowFeedbackClose}
              />
            )}
          </Box>
        </>
      </div>
    </>
  )
}
