import {
  Box,
  FormControl,
  HStack,
  Icon,
  IconButton,
  Link,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
  useControllableState,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { Attachment } from '@opengovsg/design-system-react'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { BiErrorCircle, BiTrash } from 'react-icons/bi'
import { useDebounce } from 'use-debounce'

import { useValidateBulkLetterMutation } from '~features/issue/hooks/templates.hooks'
import useParseCsv from '~features/issue/hooks/useParseCsv'
import {
  EMAIL_KEY,
  NRICFIN_KEY,
  PHONENUMBER_KEY,
} from '~shared/constants/letters'
import {
  BulkLetterValidationResultError,
  CitizenNotificationMethod,
  CreateBulkLetterDto,
} from '~shared/dtos/letters.dto'
import { arrToCsv } from '~utils/csvUtils'
import { pluraliseIfNeeded } from '~utils/stringUtils'

import { BulkLetterIssueFormState } from '../../states/BulkLetterIssueFormState'
import { BulkIssueCardContainer } from '../BulkIssueCardContainer'
import { BulkIssueCsvErrorModal } from '../modals/BulkIssueCsvErrorModal'
import { SendSampleForm } from '../SendSampleForm'

interface BulkIssueUploadCsvCardProps {
  setUploadedCsvValid: (validity: boolean) => void
}
function getRequiredFields(fields: [boolean, string][]): string[] {
  return fields
    .filter(([isRequired]) => isRequired)
    .map(([, fieldName]) => fieldName)
}
// TODO: might be worth tidying up the logic in this file to streamline the parsing and validation logic [p3]
export const BulkIssueUploadCsvCard = ({
  setUploadedCsvValid,
}: BulkIssueUploadCsvCardProps) => {
  // WATCHED VALUES
  const { setValue, watch } = useFormContext<BulkLetterIssueFormState>()
  const template = watch('selectedTemplate')
  const citizenNotificationMethod = watch('citizenNotificationMethod')
  const isAuthEnabled = watch('isAuthEnabled')
  const isSendViaSms =
    citizenNotificationMethod === CitizenNotificationMethod.SMS
  const isSendViaEmail =
    citizenNotificationMethod === CitizenNotificationMethod.EMAIL
  const isSendViaSingPost =
    citizenNotificationMethod === CitizenNotificationMethod.PHYSICAL

  // FORM STATE
  const templateFields: string[] = template
    ? [
        ...template.fields,
        ...getRequiredFields([
          [isSendViaSms, PHONENUMBER_KEY],
          [isSendViaEmail, EMAIL_KEY],
          [isAuthEnabled, NRICFIN_KEY],
        ]),
      ]
    : []

  const {
    parsedArr,
    reset,
    error: parseCsvError,
    phoneNumbers,
    emails,
    addresses,
    nricFins,
    uploadCsv,
    revalidateCsv,
  } = useParseCsv(templateFields)
  const [file, setFile] = useControllableState<File | undefined>({})
  const [uploadCsvErrors, setUploadCsvErrors] = useState<
    BulkLetterValidationResultError[]
  >([])
  const errorRowCount = new Set(uploadCsvErrors.map((error) => error.id)).size

  // Download Template
  const downloadSample = () => {
    arrToCsv(`${template?.name || ''} Template.csv`, templateFields)
  }

  useEffect(() => {
    void revalidateCsv()
    setUploadCsvErrors([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [citizenNotificationMethod, isAuthEnabled])

  const { mutateAsync: validate, isLoading } = useValidateBulkLetterMutation({
    onError: (errors: BulkLetterValidationResultError[]) =>
      setUploadCsvErrors(errors),
  })

  // TODO: this is very hacky, to clean up so that we don't need to debounce as a c
  const isUploadSuccessful =
    !!file &&
    parseCsvError.length === 0 &&
    uploadCsvErrors.length === 0 &&
    !isLoading
  const [isUploadedCsvValid] = useDebounce(isUploadSuccessful, 200)
  useEffect(() => {
    setUploadedCsvValid(isUploadedCsvValid)
  }, [isUploadedCsvValid])

  useEffect(() => {
    // trigger backend validation on upload if frontend validation passes
    if (parsedArr.length > 0 && parseCsvError.length === 0)
      void handleValidation()
  }, [parsedArr, parseCsvError])

  const handleValidation = () => {
    if (!template || !file) return
    const reqBody: CreateBulkLetterDto = {
      templateId: template.id,
      templateVersion: template.version,
      letterParamMaps: parsedArr,
      isAuthEnabled: isAuthEnabled,
      nricFins: nricFins,
    }
    if (isSendViaSms) {
      reqBody.createNotificationDto = {
        recipients: phoneNumbers,
        notificationMethod: citizenNotificationMethod,
      }
    }
    if (isSendViaEmail) {
      reqBody.createNotificationDto = {
        recipients: emails,
        notificationMethod: citizenNotificationMethod,
      }
    }
    if (isSendViaSingPost) {
      reqBody.createNotificationDto = {
        recipients: addresses,
        notificationMethod: citizenNotificationMethod,
      }
    }
    void validate(reqBody)
    setValue('createBulkLetterDto', reqBody)
  }

  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <BulkIssueCardContainer>
      <FormControl isInvalid={isUploadSuccessful}>
        <VStack align="left" spacing="24px" pb="40px">
          <VStack align="left" textColor={'grey.600'} spacing={6}>
            <OrderedList textStyle="body-1" px={'4px'} spacing={1}>
              <ListItem>
                <Link onClick={downloadSample}>Download CSV template here</Link>
              </ListItem>
              <ListItem>
                Fill each column in the CSV, then upload it below
              </ListItem>
            </OrderedList>
            <UnorderedList
              style={{ fontSize: '14px' }}
              px={'48px'}
              backgroundColor={'grey.50'}
              py="16px"
              textColor="grey.500"
              borderRadius={'4px'}
              spacing={2}
            >
              <li>
                <strong>
                  IMPORTANT: Do not modify column headers in the template
                </strong>
                , or the fields in your letter will not be filled.
              </li>
              {isSendViaEmail && (
                <>
                  <li>
                    <strong>Ensure recipient email addresses</strong> are in the
                    correct format (e.g. name@email.com)
                  </li>
                </>
              )}
              {isSendViaSms && (
                <>
                  <li>
                    <strong>Ensure recipient phone numbers</strong> are in the
                    correct format (e.g. 87654321)
                  </li>
                </>
              )}
              {isAuthEnabled && (
                <>
                  <li>
                    <strong>Ensure NRIC/FIN numbers</strong> are in the correct
                    format (e.g. S1234567A)
                  </li>
                </>
              )}
              {isSendViaSingPost && (
                <>
                  <li>
                    <strong>Ensure residential address</strong> are in the
                    correct format (e.g. 123 Main Street, #01-01, Singapore
                    123456)
                  </li>
                </>
              )}
              {isSendViaEmail && !isAuthEnabled ? (
                <>
                  <li>
                    <strong>
                      Each row in the CSV can include up to two email
                      recipients.
                    </strong>{' '}
                    You can have up to 500 rows.
                  </li>
                  <li>
                    <strong>To specify two email recipients</strong>, separate
                    their email addresses with a semi-colon(;) in the Email(s)
                    column (e.g., name1@email.com;name2@email.com)
                  </li>
                </>
              ) : (
                <li>
                  <strong>
                    Each row in the CSV corresponds to 1 recipient.
                  </strong>{' '}
                  You can have up to 500 recipients.
                </li>
              )}
            </UnorderedList>
          </VStack>
          <VStack align="stretch" spacing={0} pt={'16px'}>
            {file && (
              <Box
                w={'100%'}
                bg={isUploadSuccessful ? 'green.100' : 'red.100'}
                p={'1rem 1.5rem 1rem 1.5rem'}
                borderRadius={'5px'}
                mb={'5px'}
              >
                <HStack spacing={'auto'}>
                  <VStack align={'left'} spacing={0}>
                    <Text textStyle="subhead-1">{file.name}</Text>
                    <Text fontSize={'12px'} color={'grey.400'}>
                      {file.size} kB
                    </Text>
                  </VStack>

                  <IconButton
                    icon={<BiTrash size={20} />}
                    variant="clear"
                    colorScheme="green"
                    aria-label="Remove file"
                    color="grey.500"
                    onClick={() => {
                      reset()
                      setUploadCsvErrors([])
                      setFile(undefined)
                    }}
                  />
                </HStack>
              </Box>
            )}
            {file === undefined && (
              <Attachment
                onChange={(file) => {
                  reset()
                  setUploadCsvErrors([])
                  setFile(file)
                  void uploadCsv(file)
                  setUploadedCsvValid(false)
                }}
                accept={'.csv'}
                value={file}
                name={'fileInput'}
              />
            )}
          </VStack>
          {file !== undefined && parseCsvError.length > 0 && (
            <HStack align={'start'} px={'10px'}>
              <Icon
                as={BiErrorCircle}
                color="utility.feedback.critical"
                boxSize={'25px'}
              />
              <Text
                fontSize={'14px'}
                color={'utility.feedback.critical'}
                pt={'2px'}
              >
                {parseCsvError}
              </Text>
            </HStack>
          )}
          {uploadCsvErrors.length && (
            <VStack align="left" spacing="1">
              <HStack>
                <Icon
                  as={BiErrorCircle}
                  color="utility.feedback.critical"
                  boxSize={'25px'}
                />
                <Text
                  fontSize={'14px'}
                  textColor="utility.feedback.critical"
                  pt={'2px'}
                >
                  {errorRowCount}{' '}
                  {pluraliseIfNeeded([...Array<number>(errorRowCount)], 'row')}{' '}
                  contain errors. Please fix and re-upload the file.{' '}
                </Text>
              </HStack>
              <Text paddingLeft={'28px'}>
                <Link
                  onClick={onOpen}
                  fontWeight={'600'}
                  fontSize={'14px'}
                  textDecoration={'underline'}
                  textColor="utility.feedback.critical"
                  _hover={{ textColor: 'utility.feedback.critical' }}
                >
                  View error summary
                </Link>
              </Text>
            </VStack>
          )}
          <BulkIssueCsvErrorModal
            isOpen={isOpen}
            onClose={onClose}
            uploadCsvErrors={uploadCsvErrors}
          />
        </VStack>
      </FormControl>
    </BulkIssueCardContainer>
  )
}
