import { useQuery, UseQueryResult } from '@tanstack/react-query'

import { api } from '~lib/api'
import {
  GetAuthUrlResponseDto,
  SgidCallbackResponseDto,
} from '~shared/dtos/sgid.dto'

export const useGetAuthUrl = (
  letterId: string,
): UseQueryResult<GetAuthUrlResponseDto> => {
  return useQuery(['letterId', letterId], () =>
    api
      .url(`/sgid/get-auth-url`)
      .get(`/${letterId}`)
      .json<GetAuthUrlResponseDto>(),
  )
}

export const useSgIdCallback = (
  code: string,
  letterId: string,
): UseQueryResult<SgidCallbackResponseDto> => {
  return useQuery(
    [code, letterId],
    () =>
      api
        .url(`/sgid/handle-callback?code=${code}&state=${letterId}`)
        .post()
        .json<SgidCallbackResponseDto>(),
    { retry: false },
  )
}
