import { Box, Flex, HStack, Image, Text, VStack } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'

import SingpassLogo from '~/assets/SingpassLogoWhite.svg'

import { useGetAuthUrl } from './hooks/authEnabledLetters.hooks'

export const AuthEnabledLetterLoginPage = ({
  authEnabledLetterPublicId,
  message,
}: {
  authEnabledLetterPublicId: string
  message: string
}) => {
  const { data } = useGetAuthUrl(authEnabledLetterPublicId ?? '')
  return (
    <VStack minWidth="100%" align="stretch" spacing={0}>
      <Box flex={1}>
        <Flex justifyContent="center" mt="3rem" mb="3rem">
          <VStack>
            <Text>{message}</Text>
            <Button
              bgColor="#F4333D"
              size="md"
              height="3.5rem"
              _active={{ backgroundColor: '#B80A12' }}
              transition="200ms"
              onClick={() => {
                if (!data) return
                window.location.href = data.url
              }}
            >
              <HStack justifyContent="center">
                <Text fontWeight={600}>{'Log in with'}</Text>
                <Image src={SingpassLogo} height="2rem" />
              </HStack>
            </Button>
          </VStack>
        </Flex>
      </Box>
    </VStack>
  )
}
