import {
  Box,
  CloseButton,
  HStack,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import { useSteps } from '@chakra-ui/stepper'
import { useFeatureValue } from '@growthbook/growthbook-react'
import { useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'

import { handleDownloadCsvReport } from '~features/dashboard/components/DownloadCsvReportButton'
import { BulkIssueSendLettersConfirmationModal } from '~features/issue/components/modals/IssueConfirmationModal'
import { SampleLetterModal } from '~features/issue/components/modals/SampleLetterModal'
import { CitizenNotificationMethod } from '~shared/dtos/letters.dto'
import { GrowthBookFeatures } from '~shared/types/feature-flag'

import { BulkIssueSettingsCard } from './components/cards/BulkIssueSettingsCard'
import { BulkIssueTemplateSelectionCard } from './components/cards/BulkIssueTemplateSelectionCard'
import { BulkIssueUploadCsvCard } from './components/cards/BulkIssueUploadCsvCard'
import { LocationStateTemplate } from './components/constants/locationState'
import { ExitConfirmationModal } from './components/modals/ExitConfirmationModal'
import { IssueSuccessModal } from './components/modals/IssueSuccessModal'
import { PaginationControl } from './components/PaginationButtons'
import { PreviewNotificationMethod } from './components/previews/PreviewNotificationMethod'
import { PreviewTemplate } from './components/previews/PreviewTemplate'
import { BulkIssueStepper } from './components/Stepper'
import { useCreateBulkLetterMutation } from './hooks/templates.hooks'
import { BulkLetterIssueFormState } from './states/BulkLetterIssueFormState'

export const BulkIssuePage = (): JSX.Element => {
  const methods = useForm<BulkLetterIssueFormState>()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { activeStep, goToNext, goToPrevious } = useSteps()

  const { state } = useLocation() as LocationStateTemplate
  useEffect(() => {
    if (state && state.template) {
      methods.setValue('selectedTemplate', state.template)
    }
  }, [methods, state])

  const navigate = useNavigate()

  const selectedTemplate = methods.watch('selectedTemplate')
  const citizenNotificationMethod = methods.watch('citizenNotificationMethod')
  const isAuthEnabled = methods.watch('isAuthEnabled')
  const createBulkLetterDto = methods.watch('createBulkLetterDto')

  const [createdBatchId, setCreatedBatchId] = useState<number | null>()

  const {
    isOpen: isOpenConfirmSendModal,
    onOpen: onOpenConfirmSendModal,
    onClose: onCloseConfirmSendModal,
  } = useDisclosure()

  const {
    isOpen: isOpenConfirmExitModal,
    onOpen: onOpenConfirmExitModal,
    onClose: onCloseConfirmExitModal,
  } = useDisclosure()

  const {
    isOpen: isOpenSuccessModal,
    onOpen: onOpenSuccessModal,
    onClose: onCloseSuccessModal,
  } = useDisclosure()

  const {
    isOpen: isOpenSampleLetterModal,
    onOpen: onOpenSampleLetterModal,
    onClose: onCloseSampleLetterModal,
  } = useDisclosure()

  const sampleTemplateId = useFeatureValue(
    GrowthBookFeatures.sampleTemplateId,
    0,
  )

  useEffect(() => {
    let mounted = true
    if (selectedTemplate?.id === sampleTemplateId) {
      setTimeout(() => {
        if (!mounted) return
        onOpenSampleLetterModal()
      }, 800)
    }
    return () => {
      // cleanup function, stops the modal from opening if the user switches templates before timeout is over
      mounted = false
    }
  }, [selectedTemplate, sampleTemplateId, onOpenSampleLetterModal])

  const { mutateAsync, isLoading: isSending } = useCreateBulkLetterMutation({
    onSuccess: (res) => {
      onCloseConfirmSendModal()
      setCreatedBatchId(res.batchId)
      onOpenSuccessModal()
      if (citizenNotificationMethod === CitizenNotificationMethod.NONE) {
        setTimeout(() => {
          handleDownloadCsvReport(res)
        }, 3000)
      }
    },
  })

  const [isUploadedCsvValid, setIsUploadedCsvValid] = useState(false)

  const headerTitles = [
    'Select a letter template',
    'How would you like to send your letter?',
    'Fill and upload CSV template',
  ]

  return (
    <FormProvider {...methods}>
      <HStack height="100vh" spacing={0} overflow={'hidden'}>
        <VStack
          bg="standard.white"
          width={'40%'}
          align={'start'}
          height={'100vh'}
          padding="40px"
          paddingBottom={'0px'}
          paddingTop="30px"
          spacing="32px"
          position="relative"
        >
          <CloseButton
            onClick={
              methods.formState.isDirty
                ? onOpenConfirmExitModal
                : () => navigate(-1)
            }
            borderRadius={'30px'}
            borderWidth={'1px'}
          />
          {/* height of box = 100% - PaginationControlHeight 92px - CloseButtonHeight 44px - Spacing between button and box start 32px */}
          <Box
            maxH="calc(100% - 92px - 44px - 32px)"
            w="100%"
            overflowY="auto"
            pr={4}
          >
            <BulkIssueStepper activeStepIndex={activeStep} />
            <VStack pt={10} spacing={6} align={'left'}>
              <Text textStyle="h4">{headerTitles[activeStep]}</Text>
              {activeStep === 0 && <BulkIssueTemplateSelectionCard />}
              {activeStep === 1 && <BulkIssueSettingsCard />}
              {activeStep === 2 && (
                <BulkIssueUploadCsvCard
                  setUploadedCsvValid={setIsUploadedCsvValid}
                />
              )}
            </VStack>
          </Box>
          <PaginationControl
            goToPrevious={activeStep !== 0 ? goToPrevious : null}
            goToNext={activeStep !== 2 ? goToNext : onOpenConfirmSendModal}
            canGoNext={
              activeStep === 0
                ? !!selectedTemplate
                : activeStep === 1
                ? !!citizenNotificationMethod
                : activeStep === 2
                ? isUploadedCsvValid
                : true
            }
            nextButtonText={
              activeStep === 2 && isUploadedCsvValid
                ? citizenNotificationMethod === CitizenNotificationMethod.NONE
                  ? 'Next: Create letter links'
                  : 'Next: Review and send'
                : null
            }
          />
        </VStack>
        <Box
          bg={'slate.800'}
          width={'60%'}
          height={'100%'}
          display="flex"
          justifyContent="center"
        >
          {activeStep === 0 && (
            <PreviewTemplate
              templateHtml={selectedTemplate?.html}
              isPlaceholdersHighlighted={true}
            />
          )}
          {activeStep === 1 && selectedTemplate && (
            <PreviewNotificationMethod
              template={selectedTemplate}
              citizenNotificationMethod={citizenNotificationMethod}
              isAuthEnabled={isAuthEnabled}
            />
          )}
          {activeStep === 2 &&
            selectedTemplate &&
            (createBulkLetterDto &&
            createBulkLetterDto.letterParamMaps[0] &&
            isUploadedCsvValid ? (
              <PreviewTemplate
                templateHtml={selectedTemplate.html}
                letterParamMap={createBulkLetterDto?.letterParamMaps[0]}
                isPlaceholdersHighlighted={true}
              />
            ) : (
              <PreviewTemplate
                templateHtml={selectedTemplate.html}
                isPlaceholdersHighlighted={true}
              />
            ))}
        </Box>
        <BulkIssueSendLettersConfirmationModal
          isOpen={isOpenConfirmSendModal}
          onClose={onCloseConfirmSendModal}
          isLoading={isSending}
          onConfirmSendLetters={() => mutateAsync(createBulkLetterDto)}
          letterCount={createBulkLetterDto?.letterParamMaps?.length || 0}
        />
        <ExitConfirmationModal
          isOpen={isOpenConfirmExitModal}
          onClose={onCloseConfirmExitModal}
          onConfirm={() => navigate('/admin/letters')}
        />
        <SampleLetterModal
          isOpen={isOpenSampleLetterModal}
          onClose={onCloseSampleLetterModal}
        />
        {createdBatchId && ( // success only if createdBatchId exists
          <IssueSuccessModal
            isOpen={isOpenSuccessModal}
            onClose={() => {
              onCloseSuccessModal()
              navigate(`/admin/batches/${createdBatchId}`, {
                state: {
                  // open feedback if issued template is not sample template
                  shouldOpenFeedback: selectedTemplate?.id !== sampleTemplateId,
                },
              })
            }}
          />
        )}
      </HStack>
    </FormProvider>
  )
}
