import { Box, Flex, Image, Text } from '@chakra-ui/react'
import { Button } from '@opengovsg/design-system-react'
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom'

import { AppFooter } from '~/app/AppFooter'
import LetterSgLogoSvg from '~/assets/Logo.svg'
import SingpassLoadingSvg from '~/assets/SingpassLoading.svg'

import { useSgIdCallback } from './hooks/authEnabledLetters.hooks'

const TextStyle = {
  fontSize: '16px',
  lineHeight: '24px',
  fontWeight: 400,
}

export const AuthEnabledLetterCallbackPage = () => {
  const [searchParams] = useSearchParams()
  const code = searchParams.get('code')
  const letterId = searchParams.get('state')
  const navigate = useNavigate()

  const {
    data: sgidCallbackData,
    isLoading: isLoadingData,
    isError,
  } = useSgIdCallback(code ?? '', letterId ?? '')

  if (sgidCallbackData && !isLoadingData) {
    return <Navigate to={`/${sgidCallbackData.letterId ?? ''}`} />
  }

  return (
    <>
      <Box flex={1}>
        <Flex justifyContent="center" mt="3rem">
          <Image
            src={SingpassLoadingSvg}
            alt="Singpass loading image"
            loading="eager"
            width="18rem"
          />
        </Flex>
        <Flex
          flexDir="column"
          alignItems="center"
          bgColor="white"
          w="full"
          boxShadow="0px 0px 20px 0px rgba(104, 104, 104, 0.30)"
        >
          <Text sx={TextStyle} mt="1.5rem">
            Fetching your Singpass...
          </Text>
          {letterId && isError && (
            <>
              <Text sx={TextStyle} mt="1.5rem" mb="1.5rem">
                Stuck on this page? Try logging in again. If the problem
                persists, try a different browser.
              </Text>
              <Button mb="2rem" onClick={() => navigate(`/${letterId}`)}>
                Log in again
              </Button>
            </>
          )}
          <Image
            src={LetterSgLogoSvg}
            alt={`LetterSG logo`}
            loading="eager"
            width="6rem"
            mt="1.5rem"
            mb="4rem"
          />
        </Flex>
      </Box>
      <AppFooter />
    </>
  )
}
